@import url(https://fonts.googleapis.com/css?family=Roboto:400,700|Montserrat:400,700|PT+Mono:300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.paises{width:100%}@media only screen and (min-width: 600px){.paises{width:120px}}

.header__title{color:#333;font-family:'Montserrat', sans-serif;font-weight:normal;display:inline-block;font-size:22px;margin:0}.header-title__second{font-weight:bold;text-transform:uppercase}.header-title__special{color:red;font-family:'PT Mono', monospace;font-size:.9em}.header-datatrend{margin:0 10%}.no-header{height:0;min-height:0;padding:0;transform:translate(0, -110%);transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);display:none}.no-header *{display:none}.show-header{height:60px;transform:translate(0, 0);transition:transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1)}.mobile-only{display:none}.mobile-over{display:inline}@media (prefers-color-scheme: dark){.header__title{color:#fff}.header-title__special{color:#ff6c6c}}@media only screen and (max-width: 600px){.header__title{display:block}.header-datatrend{width:50%;margin:10px 0;display:inline-block}.mobile-only{display:inline}.mobile-over{display:none}}@media only screen and (min-width: 601px) and (max-width: 1023px){.header-datatrend{margin:0 5%}}

.home{width:100%;height:calc(100vh - 60px);max-height:500px;display:flex;justify-content:center;flex-direction:column}.home::after{content:' ';display:block;z-index:-1;background:url("/images/background.jpeg") no-repeat center center;background-size:cover;opacity:.25;width:100%;height:calc(100vh - 60px);max-height:500px;position:absolute}.home__content{flex:0 0;text-align:left;font-family:'Montserrat', sans-serif;color:#000;padding:0 10%}.home__title{color:#333;font-family:'Montserrat', sans-serif;font-weight:normal;font-size:2.2em}.home-title__second{font-weight:bold;text-transform:uppercase}.home-title__special{color:red;font-family:'PT Mono', monospace;font-size:.9em}.home__text{font-size:1.4em}.home__extra-text{color:#666}@media (prefers-color-scheme: dark){.home__content{color:#e4e4e4}.home__extra-text{color:#ccc}.home__title{color:#fff}.home-title__special{color:#ff6c6c}}

.lds-roller{display:inline-block;position:relative;width:80px;height:80px}.lds-roller div{-webkit-animation:lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;animation:lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;transform-origin:40px 40px}.lds-roller div:after{content:" ";display:block;position:absolute;width:7px;height:7px;border-radius:50%;background:#fff;margin:-4px 0 0 -4px}.lds-roller div:nth-child(1){-webkit-animation-delay:-0.036s;animation-delay:-0.036s}.lds-roller div:nth-child(1):after{top:63px;left:63px}.lds-roller div:nth-child(2){-webkit-animation-delay:-0.072s;animation-delay:-0.072s}.lds-roller div:nth-child(2):after{top:68px;left:56px}.lds-roller div:nth-child(3){-webkit-animation-delay:-0.108s;animation-delay:-0.108s}.lds-roller div:nth-child(3):after{top:71px;left:48px}.lds-roller div:nth-child(4){-webkit-animation-delay:-0.144s;animation-delay:-0.144s}.lds-roller div:nth-child(4):after{top:72px;left:40px}.lds-roller div:nth-child(5){-webkit-animation-delay:-0.18s;animation-delay:-0.18s}.lds-roller div:nth-child(5):after{top:71px;left:32px}.lds-roller div:nth-child(6){-webkit-animation-delay:-0.216s;animation-delay:-0.216s}.lds-roller div:nth-child(6):after{top:68px;left:24px}.lds-roller div:nth-child(7){-webkit-animation-delay:-0.252s;animation-delay:-0.252s}.lds-roller div:nth-child(7):after{top:63px;left:17px}.lds-roller div:nth-child(8){-webkit-animation-delay:-0.288s;animation-delay:-0.288s}.lds-roller div:nth-child(8):after{top:56px;left:12px}@-webkit-keyframes lds-roller{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes lds-roller{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}

.section{margin-top:30px;border-top:1px solid #666;margin:0 auto}.section::before{content:' ';border-top:1px solid #666;width:100%;height:5px;display:block;margin-top:3px;padding-top:30px}.section:first-child,.section:first-child::before{border-top:0}.section__title{font-family:'Montserrat', sans-serif;text-transform:uppercase;color:#222;margin-bottom:0}.section__subtitle{font-family:'Montserrat', sans-serif;color:#222;margin-top:0;margin-bottom:5px}@media (prefers-color-scheme: dark){.section:first-child,.section:first-child::before{border-top-color:#eee}.section__title,.section__subtitle{color:#f4f4f4}}.section__error{text-align:center;padding:10px;max-width:650px;margin:0 auto}.section__loading{height:calc(100vh - 720px);min-height:200px;display:flex;justify-content:center;align-items:center;font-size:24px;text-align:center}

.footer{background:#f1f1f1;color:#666;padding:15px;margin-top:30px;line-height:20px;text-align:center}.footer__resources{list-style-type:none}.footer a{color:#333}.footer__resources li{margin:5px 0}@media (prefers-color-scheme: dark){.footer{background-color:#333;color:#e4e4e4}.footer a{color:#fff}}

*,:before,:after{box-sizing:border-box;-webkit-tap-highlight-color:rgba(0,0,0,0)}body{font-family:"Roboto", sans-serif;color:#666}header{padding:15px 15px 1px 15px;position:-webkit-sticky;position:sticky;top:0;min-height:60px;background:#ffffff;box-shadow:0 0 0.5rem rgba(83,78,76,0.5);z-index:1}.grafico{height:400px}.paises{float:right;margin-top:-10px}.top-container{padding:15px}.contenido{padding:15px 15px 1px}.content{padding:0}.container{margin:0 auto;overflow:hidden}@media (prefers-color-scheme: dark){body{background-color:#444;color:#e4e4e4}header{background-color:#444;color:#e4e4e4}a{color:#e39777}img{-webkit-filter:grayscale(30%);filter:grayscale(30%)}text{fill:#e4e4e4 !important}.class__option>div>div>div>div:first-child{color:#e4e4e4}.class__option>div>div:nth-child(3){background:#444}.class__option>div>div:nth-child(3)>div>div:hover{background:#666}.MuiFormHelperText-root{color:#e4e4e4 !important}}@media only screen and (max-width: 600px){header{min-height:120px}}.sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);border:0}

