@import '../variables.scss';

.section {
  margin-top: 30px;
  border-top: 1px solid $lt-border-color;
  margin: 0 auto;
}

.section::before {
  content: ' ';
  border-top: 1px solid $lt-border-color;
  width: 100%;
  height: 5px;
  display: block;
  margin-top: 3px;
  padding-top: 30px;
}

.section:first-child, .section:first-child::before {
  border-top: 0;
}

.section__title {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  color: $lt-title-color;
  margin-bottom: 0;
}

.section__subtitle {
  font-family: 'Montserrat', sans-serif;
  color: $lt-title-color;
  margin-top: 0;
  margin-bottom: 5px;
}

@media (prefers-color-scheme: dark) {
  .section:first-child, .section:first-child::before {
    border-top-color: $dt-border-color;
  }

  .section__title, .section__subtitle {
    color: $dt-title-color;
  }
}

.section__error {
  text-align: center;
  padding: 10px;
  max-width: 650px;
  margin: 0 auto;
}

.section__loading {
  height: calc(100vh - 720px);
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-align: center;
}
