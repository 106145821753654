@import "../variables.scss";
@import "../mixins.scss";

.header__title {
  @include logo-base;
  display: inline-block;
  font-size: 22px;
  margin: 0;
}

.header-title__second {
  @include logo-second;
}

.header-title__special {
  @include logo-special;
}

.header-datatrend {
  margin: 0 10%;
}

.no-header {
  height: 0;
  min-height: 0;
  padding: 0;
  transform: translate(0, -110%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  display: none;
}

.no-header * {
  display: none;
}

.show-header {
  height: 60px;
  transform: translate(0, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.mobile-only {
  display: none;
}

.mobile-over {
  display: inline;
}

@media (prefers-color-scheme: dark) {
  .header__title {
    color: $dt-logo-color;
  }

  .header-title__special {
    color: $dt-logo-special;
  }
}

@media only screen and (max-width: 600px) {
  .header__title {
    display: block;
  }

  .header-datatrend {
    width: 50%;
    margin: 10px 0;
    display: inline-block;
  }

  .mobile-only {
    display: inline;
  }

  .mobile-over {
    display: none;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1023px) {
  .header-datatrend {
    margin: 0 5%;
  }
}
