// Light-Theme
//// App
$lt-primary-color: #666;
$lt-bg-color: #f6f9f8;

//// Section
$lt-title-color: #222;
$lt-border-color: #666;

//// Logo
$lt-logo-color: #333;
$lt-logo-special: red;

//// Footer
$lt-footer-bg: #f1f1f1;
$lt-foooter-ref: #333;

// DarkTheme
//// App
$dt-primary-color: #e4e4e4;
$dt-bg-color: #444;
$dt-ref-color: #e39777;

//// Section
$dt-title-color: #f4f4f4;
$dt-border-color: #eee;

//// Logo
$dt-logo-color: #fff;
$dt-logo-special: #ff6c6c;

//// Footer
$dt-footer-bg: #333;
$dt-foooter-ref: #fff;
