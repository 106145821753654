@import '../variables.scss';
@import '../mixins.scss';

.home {
  width: 100%;
  height: calc(100vh - 60px);
  max-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home::after {
  content: ' ';
  display: block;
  z-index: -1;
  background: url('/images/background.jpeg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: .25;
  width: 100%;
  height: calc(100vh - 60px);
  max-height: 500px;
  position: absolute;
}

.home__content {
  flex: 0 0;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  padding: 0 10%;
}

.home__title {
  @include logo-base;
  font-size: 2.2em;
}

.home-title__second {
  @include logo-second;
}

.home-title__special {
  @include logo-special;
}

.home__text {
  font-size: 1.4em;
}

.home__extra-text {
  color: #666;
}

@media (prefers-color-scheme: dark) {
  .home__content {
    color: #e4e4e4;
  }

  .home__extra-text {
    color: #ccc;
  }

  .home__title {
    color: $dt-logo-color;
  }

  .home-title__special {
    color: $dt-logo-special;
  }
}
